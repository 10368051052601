import { useState, useEffect } from 'react'
import Link from 'next/link'
import styles from '@/components/navhub/NavCat.module.css'
import data from '@/data/navcat.json'

const NavHub = ({ label = 'Menu' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [navData, setNavData] = useState([])

  useEffect(() => {
    setNavData(data.elements)
  }, [])

  useEffect(() => {
    const bodyClasses = document.body.classList
    if (isOpen) {
      bodyClasses.add('modalOpen')
    } else {
      bodyClasses.remove('modalOpen')
    }
    return () => {
      bodyClasses.remove('modalOpen')
    }
  }, [isOpen])

  const renderNavItem = (item, index) => {
    let className = ''
    if (item.type === 'section') {
      className = styles.section
    } else if (item.type === 'category') {
      className = styles.category
    } else {
      className = styles.subcategory
    }

    const element = (
      <div key={index} className={className}>
        {item.icon && <span>{item.icon}</span>}
        <div>{item.name}</div>
        {navData.filter((i) => i.parent === item.id).map(renderNavItem)}
      </div>
    )

    // Making only subcategory divs clickable
    return item.url && item.type === 'subcategory' ? <Link href={item.url}>{element}</Link> : element
  }

  const renderSection = (sectionName) => {
    const items = navData.filter((item) => item.parent === null && item.name === sectionName)

    // Conditional check for sectionName
    return <div className={sectionName === 'Knowledge' ? styles.categoryItems : ''}>{items.map(renderNavItem)}</div>
  }

  return (
    <div className={styles.navbar}>
      <button className={styles.menuBtn} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? label : label}
      </button>

      {isOpen && (
        <div className={styles.modal}>
          {/*<div className={styles.ribbon}>*/}
          {/*  <div className={styles.ribbonText}>{`🎉 BETA testing coming soon!`}</div>*/}
          {/*</div>*/}
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <Link className={styles.cwLogo} href="/">
                <img className={styles.vectorIcon} alt="" src="/logo_white.svg" />
              </Link>
              <button className={styles.closeButton} onClick={() => setIsOpen(!isOpen)}>
                ✕
              </button>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.two_columns}>
                <div className={styles.column}>{renderSection('Knowledge')}</div>
              </div>
              <div className={styles.two_columns}>
                <div className={styles.one_column}>
                  <div className={styles.column}>{renderSection('News')}</div>
                  <div className={styles.column}>{renderSection('Announcements')}</div>
                </div>
                <div className={styles.one_column}>
                  <div className={styles.column}>{renderSection('Navigation')}</div>
                </div>
              </div>
            </div>
            <div className={styles.modalFooter}></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavHub
