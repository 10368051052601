import React from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { FiArrowUpRight } from 'react-icons/fi'
import styles from '@/components/navhub/NavHub.module.css'

export default function NavCategoryStatic({ title, items, custom }) {
  const variants = {
    hidden: { opacity: 0, y: '5%' },
    visible: (i) => ({ opacity: 1, y: 0, transition: { duration: 0.5, delay: i * 0.2 } }),
  }

  return (
    <motion.div
      key={custom}
      className={styles.category}
      initial="hidden"
      animate="visible"
      variants={variants}
      custom={custom}
    >
      <div className={styles.headerElement}>
        <div className={styles.iconTitle}>{title}</div>
      </div>
      {items.map((item, index) => (
        <motion.div
          key={index}
          className={styles.subcategory}
          initial="hidden"
          animate="visible"
          variants={variants}
          custom={index}
        >
          <Link key={item.url} href={item.url}>
            <div className={styles.headerElement}>
              <div className={styles.iconTitle}>
                {item.icon} {item.name}
              </div>
            </div>
          </Link>
        </motion.div>
      ))}
    </motion.div>
  )
}
